@use "./styles/breakpoints" as mobile; 

.app {
    height: 100vh;
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;

    padding: var(--spacer);
    margin-bottom: 5rem;

    overflow: auto;
    height: 100%;

    @media (min-width: mobile.$small-breakpoint) {
        padding: calc(var(--spacer) * 2);
    }

    .logo {
        height: 16rem;
    
        @media (min-width: mobile.$small-breakpoint) {
            position: absolute;
            height: 20rem;
            top: var(--spacer);
            left: var(--spacer);
        }
    }
}