@use "../styles/breakpoints" as mobile;

@mixin activeStyle {
    background-size: 100%;

    &::after {
        transform: scaleX(1);
        transform-origin: right;
    }
}

.nav {
    padding: calc(var(--spacer) / 2);
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-items: end;

    @media (max-width: mobile.$x-small-breakpoint) {
        justify-items: center;
    }

    @media (min-width: mobile.$small-breakpoint) {
        padding: var(--spacer);
    }
}

.navButtons {
    grid-column: 2/3;
    display: flex;
    gap: 1rem;
    height: fit-content;
}

// .truck {
//     fill: black;
//     stroke: white;
//     height: 3rem;
//     width: auto;
// }

.navButton {
    position: relative;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    -webkit-text-stroke: 0.05rem var(--color-accent);
    background: linear-gradient(var(--color-accent) 0 100%) left / 0 no-repeat;
    color: transparent;
    background-clip: text;
    transition: 0.5s linear;
    width: max-content;

    @media (max-width: mobile.$x-small-breakpoint) {
        font-size: 1.5rem;
        line-height: 1.8rem;
    }

    @media (min-width: mobile.$small-breakpoint) {
        font-size: 2.5rem;
        line-height: 3rem;
    }

    &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        width: 100%;
        height: 0.3rem;
        background: var(--color-accent);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
    }

    &:hover {
        @include activeStyle;
    }

    &:not(:first-child)::before {
        display: block;
        position: absolute;
        top: 0;
        left: -0.5rem;
        height: 2.4rem;
        content: "";
        border-right: 2px solid var(--color-sky-blue);

        @media (max-width: mobile.$x-small-breakpoint) {
            height: 1.8rem;
        }
    
        @media (min-width: mobile.$small-breakpoint) {
            height: 3rem;
        }
    }
}

.active {
    @include activeStyle;
}