@use "../styles/breakpoints" as mobile;

.home {
    display: flex;
    flex-direction: column;
    gap: var(--spacer);
    height: 100%;
    align-items: center;
    justify-content: center;

    @media (min-width: mobile.$small-breakpoint) {
        margin: calc(var(--spacer) * 2);
    }
}

.logoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    .logoWrapper {
        margin: var(--spacer);

        .logo {
            width: 100%;
            height: auto;
            max-width: 30rem;
        }
    }

    .hotStuff {
        display: flex;
        flex-direction: column;
        gap: var(--spacer);
        align-items: center;

        .chili {
            width: 5rem;
            padding-bottom: var(--spacer);
        }

        @media (min-width: mobile.$small-breakpoint) {
            flex-direction: row;
            gap: 0.5rem;
        }
    }

    .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
        gap: var(--spacer);

        @media (min-width: mobile.$small-breakpoint) {
            margin-top: calc(var(--spacer) * 2);
        }

        @media (max-width: mobile.$x-small-breakpoint) {
            a {
                font-size: var(--font-size-xl);
                line-height: var(--line-height-xl);
                padding: 1rem 3rem 0.7rem;
            }
        }
    }
}

