@use "../styles/breakpoints" as mobile;

.about {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacer) * 3);
    margin-top: var(--spacer);
    
    @media (min-width: mobile.$small-breakpoint) {
        margin: calc(var(--spacer) * 2);
    }
}

.aboutHeader {
    margin-bottom: var(--spacer);
}

.aboutInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacer);

    h1 {
        margin: auto;
    }

    p {
        margin-bottom: calc(var(--spacer) * 2);
    }
}

.infoBlock {
    display: flex;
    flex-direction: column;
    gap: var(--spacer);
    white-space: pre-line;
}