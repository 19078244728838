@use "../styles/breakpoints" as mobile;

.spiceMeter {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacer);
    align-items: center;
    justify-content: center;

    @media (min-width: mobile.$small-breakpoint) {
        flex-direction: row;
    }
}

.rangeContainer {
    width: 100%;
}

.rangeSlider {
    flex: 1;
    height: fit-content;
    width: 100%;
}

.chilliContainer {
    width: 10rem;

    @media (min-width: mobile.$small-breakpoint) {
        width: 15rem;
        margin-bottom: var(--spacer);
    }
}

.spiceLevel {
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
    color: var(--color-sky-blue);
    padding: 0.5rem;

    @media (min-width: mobile.$small-breakpoint) {
        font-size: var(--font-size-xl);
        line-height: var(--line-height-xl);
        font-weight: bold;
    }
}