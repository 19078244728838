.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-header);
    text-transform: lowercase;
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
    font-weight: lighter;
    color: var(--color-white);
    text-align: center;
    text-decoration: underline var(--color-accent);
    background-image: linear-gradient(to right,var(--color-dark-pink),var(--color-sky-orange));
    border-radius: var(--border-radius-xl);
    padding: 1rem 4rem 0.8rem;

    &:hover {
        background-color: #e6375a;
        background-image: none;
    }
}