//base
* {
    box-sizing: border-box;
  
    // scrollbar-width: thick;
    // scrollbar-color: var(--theme-color-brand-dim);
  
    &:disabled {
      cursor: not-allowed;
  
      opacity: 1;
    }
  
    &:focus {
      outline: 0;
    }
  
    &:focus-visible {
      outline: var(--width-outline) solid var(--color-focus);
      outline-offset: 0;
    }
  
    &::-webkit-scrollbar {
      width: 1rem;
      height: 1rem;
    }
  
    &::-webkit-scrollbar-thumb {
      border: 0.2rem solid var(--color-background);
      border-radius: 0.625rem;
  
      background: var(--color-white);
  
      &:hover {
        background-color: var(--color-accent);
      }
    }
}
  
html {
    font-size: 62.5%;
    min-height: 100vh;
}

body {
    background-color: var(--color-background);
    overflow: hidden;
    height: 100vh;
    // font-size: 1.6rem
}

::selection {
    color: var(--color-white);

    text-shadow: none;

    background-color: var(--color-accent);
}

//typography
html,
body {
  line-height: var(--line-height-base);
  font-family: var(--font-family-base) !important;
  font-weight: var(--font-weight-base);
  color: var(--font-color-base);
}

//forms
button,
input,
select {
  font-family: var(--font-family-base);

  background-color: transparent;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
select {
  font-family: var(--font-family-base);
  cursor: pointer;
}

input,
select {
  caret-color: var(--color-accent);
}