@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&family=Zen+Kaku+Gothic+Antique:wght@300&display=swap');

@font-face {
    font-family: "Eudora";
    src: url("/fonts/Eudora.ttf") format("truetype");
}

:root {
    //colors
    --color-background: #252627;
    --color-white: hsl(0deg 0% 100% / 100%);
    --color-accent: #ffbf1b;
    --color-sky-blue: #a6cbb9;
    --color-sky-orange: #ee8864ff;
    --color-pink: #ffb3deff;
    --color-dark-pink: #d46273ff;
    --color-grey: #252627;
    --color-dark-green: #547544ff;
    --color-green: #4f7700ff;
    --color-light-green: #95bd33ff;
    --color-dark-blue: #3d455d;
    --color-light-blue: #457aa8;
    --color-red: #8f1e18;
    --color-dim: #121212;
    --color-focus: #51b8c0;

    //borders
    --border-line: var(--width-line) solid var(--color-accent);
    --border-outline: var(--width-outline) solid var(--theme-color-contrast-medium);
    --border-radius-circle: 50%;
    --border-radius-sm: 0.4rem;
    --border-radius-md: 0.6rem;
    --border-radius-lg: 0.8rem;
    --border-radius-xl: 5rem;

    //spacing
    --spacer: 2rem;

    //dimensions
    --width-line: 0.1rem;
    --width-outline: 0.2rem;
    --height-input: 4.4rem;
    --dimension-button-xs: 2.2rem;
    --dimension-button-sm: 2.9rem;
    --dimension-button-md: 4rem;
    --dimension-button-lg: 5rem;
    --dimension-button-xl: 12vmin;
    --dimension-input-boolean: 1.8rem;
    --dimension-input-icon: 2.2rem;

    //padding
    --padding-lozenge: calc(var(--spacer) / 2) var(--spacer);
    --padding-rectangle: calc(var(--spacer) / 2) calc(var(--spacer) * 0.75);
    --padding-square: var(--spacer);
    --padding-top-dashboard: calc(var(--spacer) / 2);

    //typography
    --font-color-base: var(--color-white);
    --font-family-base: "Raleway", sans-serif;
    --font-family-header: "Eudora", sans-serif;
    --font-family-cursive: "Neonderthaw", cursive;
    --font-family-mono: monospace;
    --font-size-xs: 1rem;
    --font-size-sm: 1.2rem;
    --font-size-base: 1.4rem;
    --font-size-md: 1.6rem;
    --font-size-lg: 1.8rem;
    --font-size-xl: 2.2rem;
    --font-size-xxl: 2.6rem;
    --font-size-input: 1.6rem;
    --font-size-label: 1.4rem;
    --font-weight-sm: 400;
    --font-weight-base: 500;
    --font-weight-md: 600;
    --font-weight-lg: 700;
    --line-height-xs: calc(var(--font-size-xs) + 0.6rem);
    --line-height-sm: calc(var(--font-size-sm) + 0.6rem);
    --line-height-base: calc(var(--font-size-base) + 0.6rem);
    --line-height-md: calc(var(--font-size-md) + 0.6rem);
    --line-height-lg: calc(var(--font-size-lg) + 0.6rem);
    --line-height-xl: calc(var(--font-size-xl) + 0.6rem);
    --line-height-xxl: calc(var(--font-size-xxl) + 0.6rem);
    --line-height-input: calc(var(--font-size-input) + 0.6rem);
    --line-height-label: calc(var(--font-size-label) + 0.6rem);
}