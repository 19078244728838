.h1 {
    font-family: var(--font-family-base);
    font-size: var(--font-size-lg);
    line-height: var(--line-height-lg);
    color: var(--color-white);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    padding-bottom: var(--spacer);
    border-bottom: var(--border-line);
    text-align: center;
}

.displayFont {
    font-family: var(--font-family-header);
    text-transform: lowercase;
    font-size: calc(var(--font-size-xl) * 2);
    line-height: calc(var(--line-height-xl) * 2);
    font-weight: lighter;
    padding-bottom: 0;
    text-decoration: underline var(--color-accent);
    border-bottom: none;
}

.p {
    line-height: var(--line-height-md);
    font-size: var(--font-size-md);
}