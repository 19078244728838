@use "../styles/breakpoints" as mobile;

.location {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacer) * 3);
    margin-top: var(--spacer);
    
    @media (min-width: mobile.$small-breakpoint) {
        margin: calc(var(--spacer) * 2);
    }
}

.locationInfo {
    display: flex;
    flex-direction: column;
    gap: var(--spacer);

    span {
        margin-top: var(--spacer);
    }

    > p:last-child {
        padding-top: var(--spacer);
        border-top: 1px solid var(--color-sky-blue);
        margin: auto;
    }

    .openingHours {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: var(--spacer);
        padding-bottom: var(--spacer);
    }
    
    .link {
        font-size: var(--font-size-md);
        line-height: var(--line-height-md);
        color: var(--font-color-base);
        padding-left: 0.4rem;
        text-decoration: underline;
    }
}
