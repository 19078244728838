@use "../styles/breakpoints" as mobile;

.menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: var(--spacer);
    margin-top: var(--spacer);
    
    @media (min-width: mobile.$small-breakpoint) {
        margin: calc(var(--spacer) * 2);
    }

    h1:not(:first-child) {
        margin-top: var(--spacer);

        @media (min-width: mobile.$small-breakpoint) {
            margin-top: calc(var(--spacer) * 2);
        }
    }
}

.menuList {
    width: 100%;

    > li {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: max-content max-content;
        grid-column: 2/3;
        grid-row: 1/2;
        gap: var(--spacer);

        justify-items: start;
        align-items: center;
        font-size: var(--font-size-lg);
        line-height: var(--line-height-lg);
        width: 100%;

        .number {
            font-size: calc(var(--font-size-xl) * 4);
            line-height: calc(var(--line-height-xl) * 4);
            color: var(--color-accent);
            font-family: var(--font-family-header);

            @media (min-width: mobile.$small-breakpoint) {
                &:first-child {
                    padding-left: 9rem;
                }

                &:not(:first-child) {
                    padding-right: 9rem;
                }
            }
        }

        &:nth-child(even) {
            grid-template-columns: 1fr max-content;
            justify-items: end;
        }
    }
}

.menuItems {
    grid-column: 1/3;
    grid-row: 2/3;

    display: grid;
    gap: calc(var(--spacer) / 2);

    padding-bottom: calc(var(--spacer) * 2);

    > li > div {
        height: 100%;
    }

    @media (min-width: mobile.$small-breakpoint) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        padding-bottom: var(--spacer);
    }
}

.menuItem {
    display: flex;
    flex-direction: column;
    gap: var(--spacer);
}

.spiceMeter {
    grid-column: 1/3;
    grid-row: 2/3;
    width: 100%;
}

.extras {
    grid-column: 1/3;
    grid-row: 2/3;
    width: 100%;
}

.extraItems {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;

    @media (min-width: mobile.$small-breakpoint) {
        grid-template-rows: unset;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.extraItem {
    display: flex;
    justify-content: space-around;
    padding: calc(var(--spacer) * 2);

    &:not(:last-child) {
        border-bottom: var(--border-line);
    }

    @media (min-width: mobile.$small-breakpoint) {
        span {
            padding: calc(var(--spacer) * 2) 0;
        }

        &:not(:last-child) {
            border-right: var(--border-line);
            border-bottom: none;
        }
    }
}